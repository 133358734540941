import * as CONSTANT from './Constants';
import IsLowThinContent from '../components/IsLowThinContent';
import { RenderCellTable } from '../types';
import { GridColDef } from '@mui/x-data-grid';

// COMPONENTS
import SerpDomain from '../components/Serp/SerpDomain';
import KeywordLinkBlank from '../components/KeywordLinkBlank';
import ResultRankAbsolute from '../components/ResultRankAbsolute';
import KeywordRedirectLink from '../components/KeywordRedirectLink';
import ContainerIntents from '../components/KeywordIntent/Container';
import ContainsKeyword from '../components/ContainsKeyword/ContainsKeyword';
import KeywordFeatures from '../components/KeywordFeatures/KeywordFeatures';
import KeywordDifficulty from '../components/KeywordDifficulty';
import HistoryTrendChart from '../components/HistoryTrendChart';
import KeywordPosition from '../components/KeywordPosition/KeywordPosition';
import Cost from '../components/Cost';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

import { formatNumber } from './Utils';
import SerpKeyword from '../components/Serp/SerpKeyword';
import RankRangesWrapper from '../components/Recharts/RankRangesWrapper/RankRangesWrapper';
import BacklinksAnchors from '../components/Backlinks/BacklinksAnchors';
import BacklinkPageFromTitle from '../components/Backlinks/PageFromTitle';
import BacklinkDate from '../components/Backlinks/BacklinkDate';
import DomainCompetitor from '../components/Domain/DomainCompetitor/DomainCompetitor';
import Percentage from '../components/Percentage';
import KeywordIntentWithDescription from '../components/KeywordIntent/KeywordIntentWithDescription';
import ProjectProperty from '../components/Projects/ProjectProperty';
import ProjectAccount from '../components/Projects/ProjectAccount';
import Cannibalization from '../components/Cannibalization';
import { Typography } from '@mui/material';
import { formatDate } from '../utils/Utils';
import { SearchIntentCell } from '../components/KeywordIntent/SearchIntentCell';

export const GENERIC = (t, options): GridColDef<any> => ({
  field: options.field,
  headerName: options.headerName || t(`headerName.${options.field}`),
  description: t(`tooltip.${options.field}`),
  flex: options.flex ? options.flex : 0,
  minWidth: options.minWidth || 100,
  type: options.type || 'number',
  align: options.align || 'right',
  headerAlign: options.headerAlign || 'right',
  sortable: options.sortable !== false,
  filterOperators: options.filterOperators || undefined,
  valueGetter: (params, row, field) => field.field.split('.').reduce((acc, part) => acc && acc[part], row),
  renderCell: (params) => getComponent(params, options)
});


const getValue = (params: GridRenderCellParams<any, any, any>) => {
  return params.field.split('.').reduce((acc, curr) => acc && acc[curr], params.row);
};


/**
 *
 * @param col column of the table
 * @param value the value to render
 * @returns the component to be painted depending of the type of column name
 */
const getComponent: RenderCellTable = (params, options?) => {
  switch (params.field) {

    case CONSTANT.ANCHOR:
      return <BacklinksAnchors value={params.row} />;

    case CONSTANT.CANNIBALIZATION_URLS:
      return <Cannibalization data={params.row} param={'url'} />;

    case CONSTANT.CANNIBALIZATION_CLICKS:
      return <Cannibalization data={params.row} param={'clicks'} />;

    case CONSTANT.CANNIBALIZATION_IMPRESSIONS:
      return <Cannibalization data={params.row} param={'impressions'} />;

    case CONSTANT.CANNIBALIZATION_POSITIONS:
      return <Cannibalization data={params.row} param={'position'} />;

    case CONSTANT.CONTAINS_KEYWORD:
      return <ContainsKeyword value={params.row.contains_keyword} />;

    case CONSTANT.COMPARE_URL:
      return <KeywordLinkBlank href={getValue(params)} />;

    case CONSTANT.DISTRIBUTION:
      return <Percentage value={params.row.distribution} />;

    case CONSTANT.DOMAIN:
      return <KeywordRedirectLink code={options.code} link={params.row.domain} path={'/domain/view/'}
                                  showIcon={true} />;

    case CONSTANT.MAIN_DOMAIN:
      return <KeywordRedirectLink code={options.code} link={params.row.main_domain} path={'/domain/view/'}
                                  showIcon={true} />;

    case CONSTANT.DOMAIN_0:
    case CONSTANT.DOMAIN_1:
    case CONSTANT.DOMAIN_2:
      return <DomainCompetitor options={options} data={params.row} domain={options.headerName} />;

    case CONSTANT.DOMAIN_IDEAS_URLS:
      return <KeywordRedirectLink data={params.row} db={options.code} path={'/domain/keywords/'} />;

    case CONSTANT.DOMAIN_IDEAS:
      return <KeywordRedirectLink data={params.row} db={options.code} path={'/domain/keywords/'} />;

    case CONSTANT.EXTRA_KEYWORD_DIFFICULTY:
      return <KeywordDifficulty value={params.row.extra?.keyword_difficulty} />;

    case CONSTANT.FIRST_SEEN:
      return <BacklinkDate value={params.row.first_seen} row={params.row} isFirstSeen />;

    case CONSTANT.HISTORY_TREND:
      return <HistoryTrendChart data={getValue(params)} />;

    case CONSTANT.LAST_SEEN:
      return <BacklinkDate value={params.row.last_seen} row={params.row} isLastSeen />;

    case CONSTANT.PAGE_FROM_TITLE:
      return <BacklinkPageFromTitle data={params.row} code={options.code} path={'/domain/keywords/'} />;

    case CONSTANT.SEARCH_INTENT:
      //return Object.entries(params.row.search_intent).map(([key, val]) => `${key}: ${val}`).join(', ');
      return <SearchIntentCell data={params.row} />


    case CONSTANT.THIN_CONTENT_PROBLEMS:
      return (<IsLowThinContent
        data={{
          is_low_by_clicks: params.row.is_low_by_clicks,
          is_low_by_impressions: params.row.is_low_by_impressions,
          is_low_by_ctr: params.row.is_low_by_ctr
        }}
      />);

    case CONSTANT.KEYWORD:
      return <KeywordRedirectLink link={params.row.keyword} path={'/keywords/explorer/'} code={options.code} />;

    case CONSTANT.DOMAIN_POSITION_KEYWORD:
      return <KeywordRedirectLink link={params.row.keyword} path={'/keywords/explorer/'} code={options.code} />;

    case CONSTANT.KEYWORD_INFO_HISTORY:
      return <HistoryTrendChart data={params.row.keyword_info?.history} />;

    case CONSTANT.POSITION:
      return <KeywordPosition data={{ position: getValue(params), prevPos: params.row.prevPos }} />;

    case CONSTANT.PROJECT_PROPERTY:
      return <ProjectProperty property={params.row.property} options={options} />;

    case CONSTANT.PROJECT_ACCOUNT:
      return <ProjectAccount data={params.row} options={options} />;

    case CONSTANT.PROJECT_ACCOUNT_INFO:
      return <ProjectAccount data={params.row.accountInfo} options={options} />;

    case CONSTANT.RANK_RANGES:
      return <RankRangesWrapper data={params.row.rank_ranges} loading={false} height={100} />;

    case CONSTANT.SEARCH_INTENT_INFO_INTENT:
      return <ContainerIntents data={params.row.search_intent_info} />;

    case CONSTANT.SEARCH_INTENT_INFO_INTENT_WITH_DESCRIPTION:
      return <KeywordIntentWithDescription value={params.row.search_intent_info.main_intent} />;

    case CONSTANT.SERP_INFO_SERP_0_TRAFFIC_COST:
    case CONSTANT.TOTAL_TRAFFIC_COST:
    case CONSTANT.TRAFFICOST:
    case CONSTANT.KEYWORD_INFO_CPC:
    case CONSTANT.TRAFFIC_COST:
      return <Cost value={getValue(params)} />;


    case CONSTANT.INVOICE_AMOUNT:
      return <Cost value={params.row.amount / 100} />;

    case CONSTANT.INVOICE_CURRENCY:
      return (<Typography>{params.row.currency}</Typography>);

    case CONSTANT.INVOICE_DATE:
      return (<Typography>{formatDate(params.row.date * 1000)}</Typography>);

    case CONSTANT.INVOICE_STATUS:
      return (<Typography>{params.row.status}</Typography>);

    case CONSTANT.SERP_INFO_ITEM_TYPES:
      return <KeywordFeatures data={params.row.serp_info?.item_types} />;

    case CONSTANT.SERP_INFO_SERP_POSITION:
      return (<KeywordPosition
        data={{
          position: params.row.serp_info.serp[0].rank_absolute,
          prevPos: params.row.serp_info.serp[0].previous_rank_absolute
        }}
      />);


    case CONSTANT.SERP_INFO_SERP_RANK_ABSOLUTE:
      return <ResultRankAbsolute position={params.row.serp_info?.serp?.rank_absolute} />;

    case CONSTANT.SERP_INFO_SERP_DOMAIN:
      return <SerpDomain code={options.code} url={params.row.url} domain={params.row.domain} />;

    case CONSTANT.SERP_INFO_SERP_KEYWORD:
      return <SerpKeyword code={options.code} keyword={params.row.keyword} url={params.row.serp_info.serp[0].url} />;


    case CONSTANT.URL:
      return <KeywordLinkBlank href={getValue(params)} />;

    default:
      return formatNumber(getValue(params));
  }
};