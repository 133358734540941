import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { formatNumber } from '../../utils/Utils';

interface Props {
  data: {
    search_intent: number
  };
}

export const SearchIntentCell = ({ data }: Props) => {
  const { t } = useTranslation();

  if(data===undefined || data.search_intent===undefined){
    return
  }

  const key = Object.keys(data.search_intent)[0];
  const value = data.search_intent[key];

  return (
    <Tooltip arrow followCursor title={t(`intent.${key}`)}>
      <div className={'intent'}>
        <div className={key}>
          <Typography fontWeight={'700'}
                      className={key}>{key === undefined ? '—' : key.charAt(0).toUpperCase()}</Typography>
          <span className={'description'}>{t(`intent.${key}`)} {formatNumber(value)}</span>
        </div>
      </div>
    </Tooltip>
  );
};