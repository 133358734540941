import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './KeywordIntent.scss';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  value?: string;
}

const KeywordIntentWithDescription: FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  if (!value) {
    return;
  }

  return (
    <Tooltip arrow followCursor title={t(`intent.${value}`)}>
      <div className={'intent'}>
        <div className={value}>
          <Typography fontWeight={'700'}
                      className={value}>{value === undefined ? '—' : value.charAt(0).toUpperCase()}</Typography>
          <span className={'description'}>{t(`intent.${value}`)}</span>
        </div>
      </div>
    </Tooltip>
  );
};

export default KeywordIntentWithDescription;