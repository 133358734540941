import { useState, useCallback } from 'react';
import useQueriesParams from './useQueriesParams';
import { useAccessToken } from './useAccessToken';
import { domainService } from '../services/DomainService';
import { MOCK_DATA_KEYWORDS_SERVICES } from '../utils/Constants';
import {
  DomainOverview,
  KeywordResponse,
  DomainRankRanges,
  DomainBulkResponse,
  DomainSearchIntents,
  DomainBacklinkResponse,
  DomainsContentGapRequest,
  DomainsCompetitorResponse,
  DomainIdeasResponse
} from '../types';

import ContentGapDto from '../services/dtos/domainsContentGap.dto';
import DomainsOverviewHistoryDto from '../services/dtos/domainsOverviewHistory.dto';

import { useDomainsCache } from '../context/DomainsCacheContext';

interface DomainsDataParams {
  location: number;
  domain: string;
}

export const useDomainsData = (cacheKey: string) => {
  const { getCache, updateCache } = useDomainsCache();
  const cachedData = getCache(cacheKey);

  const [domainsOverview, setDomainsOverview] = useState<DomainOverview>(cachedData.domainsOverview || {});
  const [domainRankRanges, setDomainRankRanges] = useState<DomainRankRanges>(cachedData.domainRankRanges || {});
  const [domainSearchIntents, setDomainSearchIntents] = useState<DomainSearchIntents>(cachedData.domainSearchIntents || {});
  const [domainsCompetitors, setDomainsCompetitors] = useState<DomainsCompetitorResponse>(cachedData.domainsCompetitors || []);
  const [domainsOverviewHistory, setDomainsOverviewHistory] = useState<DomainsOverviewHistoryDto>(cachedData.domainsOverviewHistory || {});
  const [domainBulks, setDomainBulks] = useState<DomainBulkResponse>(cachedData.domainBulks || { items: [], totalDomains: 0 });
  const [domainIdeas, setDomainIdeas] = useState<DomainIdeasResponse>(cachedData.domainIdeas || { items: [], totalDomains: 0 });
  const [contentGapData, setContentGapData] = useState<ContentGapDto>(cachedData.contentGapData || { items: [], total: 0 });
  const [domainsBacklinks, setDomainsBacklinks] = useState<DomainBacklinkResponse>(cachedData.domainsBacklinks || { items: [], totalBacklinks: 0 });
  const [domainsKeyword, setDomainsKeyword] = useState<KeywordResponse>(cachedData.domainsKeyword || MOCK_DATA_KEYWORDS_SERVICES);

  const [bestDomainPages, setBestDomainPages] = useState<DomainIdeasResponse>()

  const [loadingIdeas, setLoadingIdeas] = useState(false);
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [loadingBacklinks, setLoadingBacklinks] = useState(false);
  const [loadingContentGap, setLoadingContentGap] = useState(false);
  const [loadingDomainBulk, setLoadingDomainBulk] = useState(false);
  const [loadingRankRanges, setLoadingRankRanges] = useState(false);
  const [loadingCompetitors, setLoadingCompetitors] = useState(false);
  const [loadingSearchIntents, setLoadingSearchIntents] = useState(false);
  const [loadingDomainsOverview, setLoadingDomainsOverview] = useState(false);
  const [loadingDomainsOverviewHistory, setLoadingDomainsOverviewHistory] = useState(false);
  const [error, setError] = useState(null);

  const token = useAccessToken();
  const validateQueriesParams = useQueriesParams();

  const fetchDomainKeyword = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingKeywords(true);
      try {
        const data = await domainService.getKeywords({
          ...filterParams,
          location: filterParams.location,
          accessToken: token
        });
        setDomainsKeyword(data.keywords);
        updateCache(cacheKey, { domainsKeyword: data.keywords });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingKeywords(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainBacklinks = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingBacklinks(true);
      try {
        const data = await domainService.getDomainBacklinks({
          ...filterParams,
          accessToken: token
        });
        setDomainsBacklinks(data.response);
        updateCache(cacheKey, { domainsBacklinks: data.response });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingBacklinks(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainCompetitors = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingCompetitors(true);
      try {
        const data = await domainService.getCompetitors({
          location: filterParams.location,
          domain: filterParams.domain,
          accessToken: token
        });
        setDomainsCompetitors(data.keywords);
        updateCache(cacheKey, { domainsCompetitors: data.keywords });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingCompetitors(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainOverview = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingDomainsOverview(true);
      try {
        const data = await domainService.getDomainOverview({
          location: filterParams.location,
          domain: filterParams.domain,
          accessToken: token
        });
        setDomainsOverview(data);
        updateCache(cacheKey, { domainsOverview: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingDomainsOverview(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainOverviewHistory = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingDomainsOverviewHistory(true);
      try {
        const data = await domainService.getDomainOverviewHistory({
          location: filterParams.location,
          domain: filterParams.domain,
          accessToken: token
        });
        setDomainsOverviewHistory(data);
        updateCache(cacheKey, { domainsOverviewHistory: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingDomainsOverviewHistory(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainContentGap = useCallback(
    async (filterParams: Omit<DomainsContentGapRequest, 'accessToken'>) => {
      setLoadingContentGap(true);
      try {
        const data = await domainService.getContentGap({
          ...filterParams,
          accessToken: token
        });
        setContentGapData(data);
        updateCache(cacheKey, { contentGapData: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingContentGap(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainRankRanges = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingRankRanges(true);
      try {
        const data = await domainService.getRankRanges({
          ...filterParams,
          accessToken: token
        });
        setDomainRankRanges(data);
        updateCache(cacheKey, { domainRankRanges: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingRankRanges(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainSearchIntents = useCallback(
    async (filterParams: DomainsDataParams) => {
      setLoadingSearchIntents(true);
      try {
        const data = await domainService.getSearchIntents({
          ...filterParams,
          accessToken: token
        });
        setDomainSearchIntents(data);
        updateCache(cacheKey, { domainSearchIntents: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingSearchIntents(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainBulk = useCallback(
    async (filterParams: any) => {
      setLoadingDomainBulk(true);
      try {
        const data = await domainService.getBulkOverview({
          ...filterParams,
          domain: filterParams.domain,
          // limit: filterParams.limit ?? 10,
          location: filterParams.location,
          accessToken: token
        });
        setDomainBulks(data);
        updateCache(cacheKey, { domainBulks: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingDomainBulk(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainIdeas = useCallback(
    async (filterParams: any) => {
      setLoadingIdeas(true);
      try {
        const data = await domainService.getIdeaOverview({
          ...filterParams,
          domain: filterParams.domain,
          // limit: filterParams.limit ?? 10,
          location: filterParams.location,
          accessToken: token
        });
        setDomainIdeas(data);
        updateCache(cacheKey, { domainIdeas: data });
      } catch (e) {
        setError(e);
      } finally {
        setLoadingIdeas(false);
      }
    },
    [token, updateCache, cacheKey]
  );

  const fetchDomainWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { location: data.db, domain: data.q };
      await Promise.allSettled([
        fetchDomainKeyword(request),
        fetchDomainOverview(request),
        fetchDomainRankRanges(request),
        fetchDomainCompetitors(request),
        fetchDomainSearchIntents(request)
      ]);
    });
  }, [validateQueriesParams, fetchDomainKeyword, fetchDomainOverview, fetchDomainRankRanges, fetchDomainCompetitors, fetchDomainSearchIntents]);

  const fetchDomainKeywordWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { location: data.db, domain: data.q };
      await fetchDomainKeyword(request);
    });
  }, [validateQueriesParams, fetchDomainKeyword]);

  const fetchDomainCompetitorWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { location: data.db, domain: data.q };
      await fetchDomainCompetitors(request);
    });
  }, [validateQueriesParams, fetchDomainCompetitors]);

  const fetchDomainIdeasWithQueries = useCallback(async () => {
    await validateQueriesParams(async (data) => {
      const request = { location: data.db, domain: data.q };
      await fetchDomainIdeas(request);
    });
  }, [validateQueriesParams, fetchDomainIdeas]);

  const fetchBestDomainPages = useCallback(
    async (filterParams: any) => {
      setLoadingIdeas(true);
      try {
        const data = await domainService.getBestDomainPages({
          ...filterParams,
          domain: filterParams.domain,
          location: filterParams.location,
          language: 'es',
          accessToken: token
        });
        setBestDomainPages(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoadingIdeas(false);
      }
    },
    [token, updateCache, cacheKey]
  );


  return {
    keywords: domainsKeyword,
    fetchDomainKeyword,
    loadingKeywords,

    domainsBacklinks,
    fetchDomainBacklinks,
    loadingBacklinks,

    competitors: domainsCompetitors,
    fetchDomainCompetitors,
    loadingCompetitors,

    domainsOverview,
    fetchDomainOverview,
    loadingDomainsOverview,

    domainsOverviewHistory: domainsOverviewHistory?.keywords,
    fetchDomainOverviewHistory,
    loadingDomainsOverviewHistory,

    contentGapData: contentGapData,
    setContentGapData,
    fetchDomainContentGap,
    loadingContentGap,

    domainRankRanges,
    fetchDomainRankRanges,
    loadingRankRanges,

    domainSearchIntents,
    fetchDomainSearchIntents,
    loadingSearchIntents,

    domainBulks,
    fetchDomainBulk,
    loadingDomainBulk,

    domainIdeas,
    fetchDomainIdeas,
    loadingIdeas,

    fetchBestDomainPages,
    bestDomainPages,

    fetchDomainWithQueries,
    fetchDomainIdeasWithQueries,
    fetchDomainKeywordWithQueries,
    fetchDomainCompetitorWithQueries,
    error
  };
};