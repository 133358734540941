export interface CountryType {
  location: number;
  country: string;
}

export const countries: ReadonlyArray<CountryType> = [
  { location: 2032, country: 'ar' },
  { location: 2152, country: 'cl' },
  { location: 2170, country: 'co' },
  //{ location: 2208, country: 'dk' },
  { location: 2724, country: 'es' },
  { location: 2233, country: 'ee' },
  //{ location: 2246, country: 'fi' },
  { location: 2484, country: 'mx' },
  { location: 2840, country: 'us' },
  { location: 2862, country: 've' }
];

export default countries;
