import { getBaseUrl } from '../utils/Utils';
import BaseService from './BaseService';
import * as ENDPOINT from './Endpoint';
import {
  DomainOverview,
  DomainRankRanges,
  DomainSearchIntents,
  DomainOverviewRequest,
  DomainsKeywordRequest,
  DomainBacklinkRequest,
  DomainRankRangesRequest,
  DomainsContentGapRequest,
  DomainSearchIntentsRequest,
  DomainBulkRequest,
  DomainBulkResponse,
  DomainIdeasResponse,
  DomainIdeasRequest
} from '../types';

import ContentGapDto from './dtos/domainsContentGap.dto';
import DomainsKeywordDto from './dtos/domainsKeyword.dto';
import DomainsBacklinksDto from './dtos/domainsBacklinks.dto';
import DomainsCompetitorDto from './dtos/domainsCompetitor.dto';
import DomainsOverviewHistoryDto from './dtos/domainsOverviewHistory.dto';

class DomainService extends BaseService {
  async getKeywords(params: DomainsKeywordRequest): Promise<DomainsKeywordDto> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_KEYWORDS}`;

    const response = await this.post(url, data, accessToken);
    return new DomainsKeywordDto(response.data);
  }

  async getCompetitors(params: DomainsKeywordRequest): Promise<DomainsCompetitorDto> {
    const { location, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_COMPETITORS}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return new DomainsCompetitorDto(response.data);
  }

  async getDomainOverview(params: DomainOverviewRequest): Promise<DomainOverview> {
    const { location, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_OVERVIEW}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getDomainBacklinks(params: DomainBacklinkRequest): Promise<DomainsBacklinksDto> {
    const { location, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_BLACKLINKS}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return new DomainsBacklinksDto(response.data);
  }

  async getDomainOverviewHistory(
    params: DomainsKeywordRequest
  ): Promise<DomainsOverviewHistoryDto> {
    const { location, domain, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_OVERVIEW_HISTORY}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return new DomainsOverviewHistoryDto(response.data);
  }

  async getContentGap(params: DomainsContentGapRequest): Promise<ContentGapDto> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_CONTENT_GAP}`;

    const response = await this.post(url, data, accessToken);
    return new ContentGapDto(response.data);
  }

  async getSearchIntents(params: DomainSearchIntentsRequest): Promise<DomainSearchIntents> {
    const { domain, location, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_INTENTS}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getRankRanges(params: DomainRankRangesRequest): Promise<DomainRankRanges> {
    const { domain, location, accessToken } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_RANK_RANGES}`;
    const data = { location, domain };

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getBulkOverview(params: DomainBulkRequest): Promise<DomainBulkResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_BULK_OVERVIEW}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getIdeaOverview(params: DomainIdeasRequest): Promise<DomainIdeasResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_IDEA_OVERVIEW}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

  async getBestDomainPages(params: DomainIdeasRequest): Promise<DomainIdeasResponse> {
    const { accessToken, ...data } = params;
    const url = `${getBaseUrl()}${ENDPOINT.SERVICE_DOMAINS_GET_BEST_DOMAIN_PAGES}`;

    const response = await this.post(url, data, accessToken);
    return response.data;
  }

}

export const domainService = new DomainService();
