import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTextareaList from '../../hooks/useTextareaList';
import { useDomainsData } from '../../hooks/useDomainsData';
import { removeNumericValue } from '../../utils/Utils';
import { LOCATION_CODE_ES } from '../../utils/Constants';
import { COLUMNS_DOMAIN_BULK_ANALYSIS } from '../../utils/TableColumnsConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';
import { SearchCacheProvider, useSearchCache } from '../../context/SearchCacheContext';
import { Grid } from '@mui/material';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import DomainFilters from '../../components/Filters/Domain/DomainFilters';
import TextareaWithCountry from '../../components/Textarea/TextareaWithCountry';
import Search from '../../components/Search';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import { useKeywordsIdeasCache } from '../../context/KeywordsIdeasCacheContext';

const CACHE_KEY_CONTEXT = 'bulk_analysis_context';
const SEARCH_CACHE_KEY = 'bulk_analysis';

const ContainerPage = () => {
  const [domain, setDomain] = useState<string[]>([]);
  const { t } = useTranslation();
  const { domainBulks, loadingDomainBulk, fetchDomainBulk } = useDomainsData(SEARCH_CACHE_KEY);
  const { handleSearchSpecificFilters } = useKeywordsFiltersContext();
  const { getSearchCache, updateSearchCache } = useSearchCache();

  const { location, handleLocation } = useRefreshQueries(SEARCH_CACHE_KEY);
  const { updateLocation } = useKeywordsIdeasCache();

  const cachedData = getSearchCache(CACHE_KEY_CONTEXT) || "";
  const [editorValue, setEditorValue, handleEditableKeyDown] = useTextareaList(cachedData);

  const refreshData = useCallback(
    async (domain: string[]) => {
      const data = {
        location,
        domain,
        ...handleSearchSpecificFilters(),
      };
      await fetchDomainBulk(data);
    },
    [location, fetchDomainBulk, handleSearchSpecificFilters]
  );

  const handleSearchBtn = useCallback(async () => {
    const editorText = editorValue || "";
    const removeNumericInput = removeNumericValue(editorText);
    const removeNumericCache = removeNumericValue(cachedData);

    if (removeNumericInput === removeNumericCache && editorText) {
      setDomain(removeNumericCache);
      await refreshData(removeNumericCache);
    } else {
      setDomain(removeNumericInput);
      await refreshData(removeNumericInput);
      updateSearchCache(CACHE_KEY_CONTEXT, editorText);
    }
  }, [editorValue, refreshData, updateSearchCache, cachedData]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            cacheKey={SEARCH_CACHE_KEY}
            placeholder={t('search_placeholder_domain_list')}
            loading={loadingDomainBulk}
            onSearch={handleSearchBtn}
            onCountry={(code: number) => {
              handleLocation(code);
              updateLocation(SEARCH_CACHE_KEY, code);
            }}
            onChange={(value: string) => setEditorValue(value)}
            lines={4}
          />
          <div className="flex gap-2 w-full items-center pt-3">
            <DomainFilters />
          </div>
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <TableComponent
            rows={domainBulks.items}
            rowCount={domainBulks.items.length}
            loading={loadingDomainBulk}
            columns={COLUMNS_DOMAIN_BULK_ANALYSIS(t, { code: location })}
            rowHeight={100}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const BulkAnalysisPage = () => {
  const FILTERS_STORAGE_KEY = 'bulk_analysis_filters';

  return (
    <KeywordsFiltersProvider storageKey={FILTERS_STORAGE_KEY}>
      <SearchCacheProvider>
        <ContainerPage />
      </SearchCacheProvider>
    </KeywordsFiltersProvider>
  );
};